'use strict'

###*
 # @ngdoc service
 # @name lpaDispatching.factory:UnitType

 # @description

###
angular
  .module 'lpaDispatching'
  .factory 'UnitType', [
    'Restangular'
    (Restangular) ->
      Restangular.service('lpa/dispatch/units/types')
  ]
